/* scrollbar */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(28, 4, 36, 0.342);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(120, 62, 138);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(105, 49, 124);
}

/*  responsive */
@media (min-width: 320px) and (max-width: 680px) {
  /* CSS */


  .lna {
    width: 35% !important;
  }
  .collapseNav {
    background: rgba(11, 2, 12, 0.611);
    margin-top: 20%;
  }

  .navbarMobile {
    display: initial !important;
  }
  .navbarDesktop {
    display: none !important;
  }

  .desktopCards {
    display: none !important;
  }
  .mobileCards {
    display: initial !important;
  }

  .contato_info {
    margin-right: 0 !important;
  }

  .historiacontainer {
    display: none !important;
  }
  .historiaResponsivo {
    display: initial !important;
  }
  .historiatextresponsivo {
    margin-left: -8%;
    margin-right: 0;
  }

  .leiEscoteiraResponsivo {
    margin-left: 5% !important;
  }
  .leisLobinhoResponsivo {
    margin-right: 5% !important;
    margin-left: 5% !important;
  }
  .promessaRowResponsivo {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .PromessaResponsivo {
    display: initial !important;
  }

  .valoresContainer {
    display: none !important;
  }
  .valoresContainerResponsivo {
    display: initial !important;
  }
  .principios,
  .metodos {
    margin-left: 4% !important;
    margin-right: 4% !important;
    text-align: justify;
  }




  .indexText {
    display: none !important;
  }
  .indexTextResponsivo {
    display: initial !important;
  }
  .indexTextResponsivo p {
    margin-left: 5% !important;
    margin-right: 5% !important;
  }
  .indexTextResponsivo ul {
    font-size: 0.85rem;
    margin-right: 10% !important;
    font-weight: 300;
    margin-left: 0 !important;
  }
  .ramosContainer {
    display: none !important;
  }
  .ramosContainerResponsivo {
    display: initial !important;
  }
  #ramos .header {
    margin-bottom: -5% !important;
  }
  #ramos {
    margin-top: 15vh !important;
  }
  .contatoContainer {
    display: none !important;
  }

  .contatoContainerResponsivo {
    display: initial !important;
  }

  .contatoContainerResponsivo h2 {
    margin-left: 5% !important;
    margin-right: 0% !important;
    font-size: 1rem;
  }
  .horario_row {
    margin-left: 0vw !important;
  }

  .horarioSpan {
    margin-top: -5vh;
    text-align: center;
    margin-left: 20vw !important;
  }

  .contato_links .row {
    margin-left: 15vw;
    margin-right: 10% !important;
  }

  .contato_links {
    margin-right: 0vw !important;
  }
  .ramos {
    margin-top: 2% !important;
    margin-left: 5% !important;
    margin-right: 5% !important;
    margin-bottom: 15% !important;
  }
  .voltarButton {
    margin-left: -10% !important;
  }
  .leiResponsivoHeader,
  .leiResponsivoText {
    margin-left: 3.5% !important;
  }

  .contato_mapa {
    margin-left: 8.5% !important;
  }
  .sectionRamo {
    display: none !important;
  }
  .sectionText {
    margin-left: 2% !important;
    margin-right: 2% !important;
  }
  .buttons {
    margin-left: 0% !important;
    margin-right: 0% !important;
  }
  .ramoResponsivo {
    display: initial !important;
  }
  .dividerResponsive {
    border: 1px solid rgba(93, 34, 161, 0.719) !important;
    width: 95vw;
  }

  .videoTag {
    filter: brightness(50%);
    display: block;
    position: absolute;
    object-fit: fill;
    width: 100% !important;
    height: 100%;
    image-rendering: optimizeQuality;
    z-index: -100;
  }
  /* fim responsivo */
}

/*  responsive
 */
.videoTag {
  filter: brightness(50%);
  display: block;
  position: absolute;
  object-fit: fill;
  width: 100%;
  height: 100%;
  image-rendering: optimizeQuality;
  z-index: -100;
}

.nav-link {
  background: none;
}
.ramos img {
  margin-bottom: 5%;
}

.navbarFixed {
  background: rgba(41, 17, 71, 0.847);
}

.imgfooter {
  margin-top: 15%;
}

.escoteiros {
  padding-top: 30px;
}
.copyrightFooter {
  align-items: center;
  align-self: center;
  justify-content: center;
  color: white;
}
.title {
  margin-left: 35%;
}

.footer {
  background-color: #06081f !important;
}
.footerLink,
.footerLink:hover,
.footerLink:active {
  background: none !important;
  border: 0 !important;
  box-shadow: none !important;
}

.youtubeText {
  color: #fff;
  letter-spacing: 0.15em;
  text-shadow: 1px -1px 0 #767676, -1px 4px 1px #737272;
}

.header {
  margin-left: 2%;
  padding-bottom: 25px;
  text-align: left;
  font-size: 40px;
  transition: opacity 0.5s;
  opacity: 1;
  font-weight: 800;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.historiacontainer {
  display: flex;
  flex-direction: column;
}
.historiatext {
  flex-direction: column;
  padding: 0 50px 50px 50px;
  text-align: justify;
  justify-content: center;
}
.voltarBtn {
  margin-left: 13vw !important;
  margin-top: -13.4vw !important;
}

.lobinhos .header {
  margin-top: -4vw;
}
.ramoImg {
  margin-top: -3vw;
  max-width: 120%;
}
.image {
  box-shadow: 0 0 8 px 8 px white inset;
  -webkit-box-shadow: 12 px 29 px 81 px 0 px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 12 px 29 px 81 px 0 px rgba(0, 0, 0, 0.75);
  box-shadow: 12 px 29 px 81 px 0 px rgba(0, 0, 0, 0.75);
  border-radius: 0.8rem;
  margin: 15px 15px 25px 25px;
}

.historiatext > p,
.principios > p,
.ramosText > p {
  font-weight: 500;
  font-size: 1rem;
  color: #fff;
}

.sectionRamo p {
  font-size: 1.1rem;
  font-weight: 500;
}
.sectionText h3 {
  font-weight: 800;
}
.sectionText {
  right: 20;
  margin-left: 5%;
}
.jangal p,
.jangal h1 {
  margin-left: 30%;
  margin-right: -80%;
}
.buttons {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  margin-right: -20%;
  margin-top: 12%;
}

.btnLobinhos,
.btnLobinhos:active,
.btnLobinhos:visited,
.btnLobinhos:focus {
  border-left: 15px solid rgb(251, 255, 0);
  background: #fff !important;
  margin-bottom: 12px;
  color: black;
}

.btnLobinhos:hover {
  background: #ccc !important;
  border-left: 15px solid rgb(151, 155, 0);
  color: black;
}

.btnEscoteiros,
.btnEscoteiros:active,
.btnEscoteiros:visited,
.btnEscoteiros:focus {
  border-left: 15px solid rgb(10, 161, 48);
  background: #fff !important;
  margin-bottom: 12px;
  color: black;
}

.btnEscoteiros:hover {
  background: #ccc !important;
  border-left: 15px solid rgb(2, 92, 24);
  color: black;
}

.btnSenior,
.btnSenior:active,
.btnSenior:visited,
.btnSenior:focus {
  border-left: 15px solid rgb(161, 10, 116);
  background: #fff !important;
  margin-bottom: 12px;
  color: black;
}

.btnSenior:hover {
  background: #ccc !important;
  border-left: 15px solid rgb(92, 2, 52);
  color: black;
}

.btnPioneiros,
.btnPioneiros:active,
.btnPioneiros:visited,
.btnPioneiros:focus {
  border-left: 15px solid rgb(161, 10, 10);
  background: #fff !important;
  margin-bottom: 12px;
  color: black;
}

.btnPioneiros:hover {
  background: #ccc !important;
  border-left: 15px solid rgb(92, 2, 2);
  color: black;
}

.ramosText {
  margin-left: 5vw;
  margin-right: 10vw;
}
.ramos {
  margin-left: 100px !important;
  height: auto;
}

#ramos .ramosContainer {
  margin-right: 10% !important;
}

.ramos img {
  width: 100%;
  height: 50%;
  margin-bottom: 30%;
}

.promessasDesktop {
  margin-right: 0 !;
  margin-left: 0 !important;
}
.promHeader:first-child {
}
.promHeader:last-child {
}
.valoresContainer,
.contatoContainer,
.promessaContainer {
  margin-left: 35px;
  margin-right: 20px;
}
.valoresContainer h4 {
  text-align: center;
  font-size: 1rem;
  font-weight: 800;
}

.contato_info {
  margin-top: 6%;
}
.contato_mapa {
  margin-right: 10%;
}
.contato_links {
  margin-left: 10%;
}
.historiatext p:last-child {
  margin-top: 60px;
}

.promessa {
  filter: invert(100%);
  height: 5% !important;
  width: 10%;
  margin-left: 44vw !important;
  margin-bottom: 5vh;
}
.valoresText {
  text-align: justify;
  font-size: 1rem;
}

.divider {
  border: 1px solid rgb(93, 34, 161);
  background-color: rgb(93, 34, 161);
  width: 100%;
}

.emblema {
  filter: opacity(60%);
}
.promHeader h2 {
  text-align: center;
  font-size: 2rem;
  font-family: "Times New Roman", Times, serif;
  font-weight: 600;
}
.promHeader p {
  text-align: justify;
}

.principios h3,
.metodos h3 {
  font-weight: 700;
  font-size: 2rem;
}
.leisLobinho,
.leiEscoteira {
  color: #92a4df;
  font-weight: 700;
  font-size: 1rem;
  text-align: justify;
  margin-left: 25%;
}

.leisLobinho {
  margin-left: 30%;
  margin-bottom: 10%;
}

#ramos header:hover,
#ramos img:hover {
  cursor: pointer !important;
}
/* anims
 */

.fadeIn {
  animation: fadeIn 8s;
  -webkit-animation: fadeIn 8s;
  -moz-animation: fadeIn 8s;
  -o-animation: fadeIn 8s;
  -ms-animation: fadeIn 8s;
}
.slideLeft {
  animation: 1s ease-out 0s 1 slideInFromLeft;
}
.slideRight {
  animation: 1s ease-out 0s 1 slideInFromRight;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0);
  }
}
